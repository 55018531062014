import React from 'react'
import { ServerConfig } from 'config'

import List from '../../components/EntryListAndEdit/List'


const Machines = () => {
  const editable_fields = [
    ["id", "ID"],
    ["name", "Name"],
    ["state", "State"],
    ["location", "Location"],
    ["cert_id", "Cert ID"],
    ["url", "URL"],
    ["port", "port"],
    ["owner", "Owner"],
    ["secret", "Secret"],
    ["operationtype_id", "Operation Type", "operationtype"],
    ["allow_cash_payout", "Cash Payout", "boolean"]
  ];

  return (
    <>
      <List
        list_get_url={`${ServerConfig.SERVER_URL}/admin/machines`}
        detail_get_url={`${ServerConfig.SERVER_URL}/admin/machines/`}
        detail_put_url={`${ServerConfig.SERVER_URL}/admin/machines/`}
        add_new_url = {`${ServerConfig.SERVER_URL}/admin/machines/`}

        column_names={["online", "operationtype_name", "allow_cash_payout_str", "name",  "secret", "edit"]}
        header_names={["Online?", "Operation Type", "Cash payout?", "Name", "Secret", ""]}

        optional_column_names={["state", "location", "cert_id", "secret", "owner_name"]}
        optional_header_names={["State", "Location", "Cert ID", "secret", "Owner"]}

        editable_fields={editable_fields}
        //ledger_get_url = '/ledger/customer/'
        show_machine_stats={true}
      />
    </>
  )
}

export default Machines
