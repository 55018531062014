import React, { useState, useEffect } from 'react'
import { ServerConfig } from 'config'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Progress from 'react-circle-progress-bar'
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/core components
import DatePicker from "react-datepicker";
import { Typography, Modal, Button, Box, Grid } from '@material-ui/core'
import _, { get } from 'lodash'

import LedgerComponent from './LedgerComponent'


const evt = new Event('customertable_is_dirty');


const useStyles = makeStyles((theme) => ({
  detailsBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    backgroundColor: theme.palette.background.default,
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
  },
}))


const DetailsAddComponent = (props) => {
  const [details, setDetails] = React.useState({})
  const [operationtypes, setOperationtypes] = React.useState([])
  const [materials, setMaterials] = React.useState([])

  const auth = useSelector(state => state.auth)

  const add_new_url = props.add_new_url;
  const editable_fields = props.editable_fields;


  // submit data and update object
  const handleSubmit = async (event) => {
    const config = { headers: { 'Content-Type': 'application/json' } }

    if (auth.access_token) {
      config.headers.Authorization = `Bearer ${auth.access_token}`
    }

    event.preventDefault();
    const data = new FormData(event.target);
    const value = Object.fromEntries(data.entries());

    const res = await axios.post(add_new_url, value, config);

    document.getElementById('details_status').innerHTML = "Inserted";
    window.dispatchEvent(evt);
  }

  useEffect(() => {
      // get enum-values for select-field if needed
      const fetchOperationtypes = async () => {
        const config = {headers:{'Content-Type':'application/json'}}

        if (auth.access_token) {
          config.headers.Authorization = `Bearer ${auth.access_token}`
        }

        const results = await axios.get(`${ServerConfig.SERVER_URL}/admin/machines/operationtypes`, config)
        setOperationtypes(results.data)
      };

      // get enum-values for materials
      const fetchMaterialtypes = async () => {
        const config = {headers:{'Content-Type':'application/json'}}

        if (auth.access_token) {
          config.headers.Authorization = `Bearer ${auth.access_token}`
        }

        const results = await axios.get(`${ServerConfig.SERVER_URL}/admin/materials`, config)
        setMaterials(results.data)
      };

    fetchMaterialtypes();
    fetchOperationtypes();
  }, [])


  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Details {details.id}
      </Typography>

      <hr />

      <form onSubmit={handleSubmit}>
        {
          editable_fields.map(function (fieldname, bla) {
            switch (fieldname[2]) {
              case "date":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input type="date" className="form-control" name={fieldname[0]} id={fieldname[0]} defaultValue="" onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }} />
                </div>
                break
              case "material":
                  return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                      <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': "150px" }}>{fieldname[1]}</label>
                      <select class="form-control" style={{ 'display': 'block', 'width': "256px" }} name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]} onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }}>
                        {_.map(materials, opt => { return (
                          (opt == details[fieldname[0]]) ? (<option selected value={opt}>{opt}</option>) : (<option value={opt}>{opt}</option>)
                          )
                          })}
                      </select>
                    </div>
                    break
              case "boolean":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px', 'width': '256px'}}>
                    <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': "150px" }}>{fieldname[1]}</label>
                    <input type="hidden" name={fieldname[0]} value={false} />
                    <div style={{'lineHeight':'38px', 'display': 'inlineBlock', 'verticalAlign':'middle'}}>
                        <input type="checkbox" class="custom-control-input" style={{'width':'20px', 'height':'20px', 'marginRight': '10px', 'verticalAlign': 'middle'}} name={fieldname[0]} id={fieldname[0]} value={true} defaultChecked={details[fieldname[0]]} onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }} />{fieldname[1]}
                    </div>
                </div>
                break
              case "operationtype":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': "150px" }}>{fieldname[1]}</label>
                  <select class="form-control" style={{ 'display': 'block', 'width': "256px" }} name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]} onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }}>
                    {_.map(operationtypes, opt => { return (
                      (opt.id == details[fieldname[0]]) ? (<option selected value={opt.id}>{opt.name}</option>) : (<option value={opt.id}>{opt.name}</option>)
                      )
                      })}
                  </select>
                </div>
                break
              default:
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} defaultValue="" onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }} />
                </div>
                break
            }

          })
        }
        <br style={{ 'clear': 'both' }} />
        <Button type='submit' style={{ 'margin': '10px', 'display': 'block', 'float': 'left' }}>Save</Button>
        <Typography id="details_status" variant="h6" component="h2" style={{ 'lineHeight': '36px', 'padding': '10px' }}></Typography>
      </form>

    </>
  );
}

// handles button and modal overlay
const AddComponent = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const auth = useSelector(state => state.auth)

  const editable_fields = props.editable_fields;
  const add_new_url = props.add_new_url

  useEffect(() => { }, [open]);

  return (
    <td>
      <Button onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.detailsBox} style={{ 'maxHeight': '100vh', 'overflowY': 'auto' }}>

          <DetailsAddComponent
            editable_fields={editable_fields}
            add_new_url={add_new_url}
          />
        </Box>
      </Modal>
    </td>
  );
};

export default AddComponent;
