import React, { useEffect } from 'react'
import {ServerConfig} from 'config'
import axios from 'axios'
import { useSelector } from 'react-redux'

// @material-ui/core components
import { Typography, Grid, Button, Box } from '@material-ui/core'
import { Table } from 'reactstrap';

import _ from 'lodash'

const evt_ledger = new Event('ledger_updated');

const LedgerComponent = (props) => {
    const [customerLedger, setCustomerLedger] = React.useState([])
    const [sumDollars, setSumDollars] = React.useState(0)
  
    const [paymentoptions, setPaymentoptions] = React.useState([]) // available options from server
    const [paymentoption, setPaymentoption] = React.useState(1) // Cash as default
    const [donees, setDonees] = React.useState([])  // available options from server
    const [donee, setDonee] = React.useState()
    const [tasktype, setTasktype] = React.useState(1) // Kiosk as default (is first value)
  
    const [transactiontype, setTransactiontype] = React.useState()
    const auth = useSelector(state => state.auth)
    const rowData = props.rowdata;

    const ledger_get_url = props.ledger_get_url;
    const ledger_allow_entries = props.ledger_allow_entries;
  
      useEffect(() => {
        const fetchCustomerLedger = async () => {
            const config = {headers:{'Content-Type':'application/json'}}
  
            if(auth.access_token){
              config.headers.Authorization = `Bearer ${auth.access_token}`
            }
  
            const results = await axios.get(`${ServerConfig.SERVER_URL + ledger_get_url}`+rowData.id, config)
            setCustomerLedger(results.data)
        }
  
        fetchCustomerLedger();
        window.addEventListener('ledger_updated', fetchCustomerLedger);
  
        const fetchPaymentoptions = async () => {
             const config = {headers:{'Content-Type':'application/json'}}
  
            if(auth.access_token){
              config.headers.Authorization = `Bearer ${auth.access_token}`
            }
  
            const results = await axios.get(`${ServerConfig.SERVER_URL}/ledger/paymentoptions`, config)
            console.log(results.data);
            setPaymentoptions(results.data); // first is id:1, name:cash, rest are donation options
            setPaymentoption(results.data[0].id);
        }
        fetchPaymentoptions();

        const fetchDoneelist = async () => {
            const config = {headers:{'Content-Type':'application/json'}}
  
            if(auth.access_token){
              config.headers.Authorization = `Bearer ${auth.access_token}`
            }
  
            const results = await axios.get(`${ServerConfig.SERVER_URL}/donee/`, config)
            console.log(results.data);
            setDonees(results.data); // first is id:1, name:cash, rest are donation options
        }
        fetchDoneelist();
      },[])

      const md2d = (md) => {
        return (md/1e6).toFixed(2);
      }
  
      // submit data and update customer
      const handleSubmit = async (event) => {
        const config = {headers:{'Content-Type':'application/json'}}
  
        if(auth.access_token){
          config.headers.Authorization = `Bearer ${auth.access_token}`
        }
  
          event.preventDefault();
          const data = new FormData(event.target);
          const value = Object.fromEntries(data.entries());
          // only do that if it's only a ledger-related transaction:
          if(transactiontype != 2 && transactiontype != 3){  // redemption donation or deposit
              const res = await axios.post(`${ServerConfig.SERVER_URL}/ledger/insert/`+rowData.id, value, config);
          }else{
              // for redemptions, we need to insert some fake containers, too, so that the stats match up
              const res = await axios.post(`${ServerConfig.SERVER_URL}/task/insert_fake_task/`+rowData.id, value, config);
              // finalize to generate ledger-entry!
              const res2 = await axios.post(`${ServerConfig.SERVER_URL}/task/finalize/${res.data['task_id']}`, {}, config);

          }
  
          window.dispatchEvent(evt_ledger);
      }
  
      const handle_transactiontype_selection = (event) => {
          setTransactiontype(event.target.value)
          if(event.target.value == 2){ // redemption donation
            setPaymentoption(2); // donate
          }
          if(event.target.value == 3){ // redemption deposit
            setPaymentoption(1); // keep
          }
      }
  
      const handle_tasktype_selection = (event) => {
          setTasktype(event.target.value);
      }
  
      const handle_payment_selection = (event) => {
          setPaymentoption(event.target.value);
      }

      const handle_donee_selection = (event) => {
        setDonee(event.target.value);
      }

      const materials = [
                        ["Aluminum", "alu"],
                        ["Glass", "glass"],
                        ["PETE", "plastic_pete"], // plastic_pete
                        ["HDPE", "plastic_hdpe"],
                        ["LDPE", "plastic_ldpe"],
                        ["PP", "plastic_pp"],
                        ["PS", "plastic_ps"],
                        ["Bi-Metal", "bimetal"],
                        ["OTHER", "plastic_other"],
                        ];
      const special_materials = [
                        ["Bag-in-Box", "bag_in_box"],
                        ["Paperboard-Carton", "paperboard_carton"],
                        ["Multilayer-Pouch", "multilayer_pouch"],
                        ];
  
      return (<>
        <hr style={{'clear':'both'}} />
          <Typography id="modal-modal-title" variant="h6" component="h2">Ledger </Typography>
  
            {ledger_allow_entries &&
                <>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid sm={12} md={6}>
                    <Box p={2}>
                    <label htmlFor="transactiontype_id">Transaction</label>
                    <select className="form-select" name="transactiontype_id" id="transactiontype_id" onChange={handle_transactiontype_selection}>
                        <option value="0">Custom</option>
                        <option value="1">Withdrawal</option>
                        <option value="2">Redemption Donation</option>
                        <option value="3">Redemption Deposit</option>
                        <option value="4">Shop purchase</option>
                        <option value="5" disabled>Redemption Buy</option>
                        <option value="6" disabled>Donation receive</option>
                        <option value="7" disabled>Account funding</option>
                        <option value="8" disabled>Recycletek fees receive</option>
                        <option value="9">Cash payout</option>
                    </select>
                    </Box>
                  </Grid>

                  <input type="text" hidden name="paymentoptions_id" id="paymentoptions_id" value={paymentoption}></input>

                  <Grid sm={12} md={6}>
                    <Box p={2}>
                    <label htmlFor="note">Note</label>
                    <input type="text" className="form-control" name="note" id="note" defaultValue="Manual entry" onChange={() => {}} />
                    </Box>
                  </Grid>
  
                   
                  {!(transactiontype == 2 || transactiontype == 3) && // no redemption
                    <>
                    <Grid sm={6} md={6}>
                    <Box p={2}>
                      <label htmlFor="amount" >Amount</label>
                      <input type="text" className="form-control" name="amount" id="amount" defaultValue="Amount (in $)" onChange={() => {}} />
                      </Box>
                    </Grid>
                    </>
                  }
  
                  
                  {(transactiontype == 2 || transactiontype == 3) && // redemption donation (2) and redemption deposit (3)
                    <>
                      {transactiontype == 2 &&
                        <>
                            <Grid sm={6} md={6}>
                                <Box p={2}>                            
                                    <label htmlFor="donee_id" >Donee</label>
                                    <select className='form-select' name='donee_id' id='donee_id' onChange={handle_donee_selection}>
                                        {donees.map(function(entry,bla){
                                            return <option value={entry.id}>{entry.business_name}</option>
                                        })
                                        }
                                    </select>
                                </Box>
                            </Grid> 
                        </>
                      }
  
                    <Grid sm={6} md={6}>
                        <Box p={2}>
                            <label htmlFor="tasktype_id">Tasktype</label>
                            <select className='form-select' name='tasktype_id' id='tasktype_id' onChange={handle_tasktype_selection}>
                                <option value="1">Kiosk</option>
                                <option value="2">Curbside</option>
                                <option value="3">Bulk</option>
                                <option value="4">DropOff</option>
                                <option value="5">Inter-Tenant</option>
                            </select>
                        </Box>
                    </Grid>
  
                    <Grid sm={6} md={6}>
                        <Box p={2}>
                            <label htmlFor="belt_id">Machine-ID</label>
                            <input type="text" className="form-control" name="belt_id" id="belt_id" defaultValue="1" />
                        </Box>
                    </Grid>

                    {
                        _.map(materials, material => {
                            return <>
                                <Grid sm={6} md={6}>
                                    <Box p={2}>
                                    <label htmlFor={"num_"+material[1]+"_lt_24oz"} style={{'display':'block', 'width':150+"px"}}>#{material[0]} lt 24oz</label>
                                    <input type="text" className="form-control" name={"num_"+material[1]+"_lt_24oz"} id={"num_"+material[1]+"_lt_24oz"} defaultValue="0" />
                                    </Box>
                                </Grid>

                                <Grid sm={6} md={6}>
                                    <Box p={2}>
                                    <label htmlFor={"num_"+material[1]+"_geq_24oz"} style={{'display':'block', 'width':150+"px"}}>#{material[0]} geq 24oz</label>
                                    <input type="text" className="form-control" name={"num_"+material[1]+"_geq_24oz"} id={"num_"+material[1]+"_geq_24oz"} defaultValue="0" />
                                    </Box>
                                </Grid>
                            </>
                        })
                    }

                    {
                        _.map(special_materials, material => {
                            return <>
                                <Grid sm={6} md={6}>
                                    <Box p={2}>
                                    <label htmlFor={"num_"+material[1]} style={{'display':'block', 'width':150+"px"}}>#{material[0]}</label>
                                    <input type="text" className="form-control" name={"num_"+material[1]} id={"num_"+material[1]} defaultValue="0" />
                                    </Box>
                                </Grid>
                            </>
                        })
                    }
                      </>
                  }
                  <Grid sm={12} md={12}>
                        <Box p={2} alignItems={'center'}>
                            <Button type='submit'>Save</Button>
                        </Box>
                      </Grid>
                  </Grid>
                </form>
                </>
            }
  
          <Table striped bordered hover>
          <thead>
          <tr><th>Date</th><th>Note</th><th>Redemption</th><th>Payout</th><th>Balance</th></tr>
          </thead>
          <tbody>
          {
              customerLedger.map(function(entry,bla){
                  let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(entry.timestamp);
  
                  if(entry.transactiontype_id == 0){ // Custom
                      return <tr>
                                  <td>{date}</td>
                                  <td>{entry.note}</td>
                                  <td>${md2d(entry.redemption_amount_microdollars)}</td>
                                  <td>${md2d(entry.withdrawal_amount_microdollars)}</td>
                                  <td>${md2d(entry.new_customer_balance)}</td>
                          </tr>
                  }else if(entry.transactiontype_id == 1 || entry.transactiontype_id == 4){ // 1: Withdrawal, 4: Ecoshop
                      return <tr>
                                  <td>{date}</td>
                                  <td>{entry.note}</td>
                                  <td></td>
                                  <td style={{'color':'red'}}>${md2d(entry.withdrawal_amount_microdollars)}</td>
                                  <td>${md2d(entry.new_customer_balance)}</td>
                          </tr>
                  }else if(entry.transactiontype_id == 2){ // Redemption donation
                       return <tr>
                                  <td>{date}</td>
                                  <td>{entry.note}</td>
                                  <td style={{'color':'grey'}}>${md2d(entry.redemption_amount_microdollars)}</td>
                                  <td></td>
                                  <td>${md2d(entry.new_customer_balance)}</td>
                          </tr>
                  }else if(entry.transactiontype_id == 3){  // Redemption
                       return <tr>
                                  <td>{date}</td>
                                  <td>{entry.note}</td>
                                  <td style={{'color':'green'}}>${md2d(entry.redemption_amount_microdollars)}</td>
                                  <td></td>
                                  <td>${md2d(entry.new_customer_balance)}</td>
                          </tr>
  
                  }else{
                       return <tr>
                                  <td>{date}</td>
                                  <td>{entry.note}</td>
                                  <td>${md2d(entry.redemption_amount_microdollars)}</td>
                                  <td>${md2d(entry.withdrawal_amount_microdollars)}</td>
                                  <td>${md2d(entry.new_customer_balance)}</td>
                          </tr>
  
                  }
              })
          }
          </tbody>
          </Table>
          </>
      );
  
  }

  export default LedgerComponent
